<template>
  <div class="card-wrapper">
    <form-wizard
      id="wizard"
      class="wizard wizard-primary sw sw-theme-default sw-justified"
      color="#3f80ea"
      @on-complete="save(true)"
      @on-save="save(false)"
      :hideSaveButton="isNew"
      :finishButtonText="isNew ? 'Create' : 'Save & Exit'"
    >
      <tab-content title="Details" description="Specify configuration details">
        <form>
          <b-form-group label="Name">
            <b-form-input
              type="text"
              placeholder="e.g. My New Flat File"
              v-model="file.name"
              required
              trim
            />
          </b-form-group>
          <b-form-group label="Description">
            <b-form-textarea
              v-model="file.description"
              placeholder="e.g. This is a description of my new flat file..."
              rows="4"
              max-rows="4"
            ></b-form-textarea>
          </b-form-group>
        </form>
      </tab-content>
      <tab-content title="Layout" description="Customize file layout">
        <b-form>
          <b-form-group label="Schema">
            <b-form-select
              :options="schemaList"
              v-model="file.schema"
              required
            />
          </b-form-group>
          <b-form-group label="Naming Convention">
            <b-form-input
              type="text"
              placeholder="e.g. MY_NEW_FILE_<YYYY><MM><DD>.TXT"
              v-model="file.naming"
              required
              trim
            />
            <b-form-text
              >The following date variables are accepted: &lt;YYYY&gt; - Full
              Year, &lt;YY&gt; - Short Year, &lt;M&gt; - Month, &lt;MM&gt; -
              Month (leading zero), &lt;D&gt; - Day, &lt;DD&gt; - Day (leading
              zero), &lt;HH&gt; - Hour, &lt;MI&gt; - Minute, &lt;SS&gt; -
              Second.</b-form-text
            >
          </b-form-group>
          <b-form-group label="Delimiter">
            <b-form-input
              type="text"
              placeholder="e.g. ,"
              v-model="file.delimiter"
              required
              trim
            />
          </b-form-group>
          <b-form-group label="Qualifier">
            <b-form-input
              type="text"
              placeholder='e.g. "'
              v-model="file.qualifier"
              trim
            />
          </b-form-group>
          <b-form-group label="Use Qualifier">
            <b-form-radio-group
              v-model="file.use_qualifier"
              :options="[
                { value: 'always', text: 'Always' },
                { value: 'needed', text: 'When Needed' },
                { value: 'never', text: 'Never' },
              ]"
            ></b-form-radio-group>
          </b-form-group>
          <b-form-group label="End of Line (EOL) Format">
            <b-form-radio-group
              v-model="file.eol_format"
              :options="[
                { value: 'linux', text: 'Linux' },
                { value: 'windows', text: 'Windows' },
                { value: 'macos', text: 'macOS' },
              ]"
            ></b-form-radio-group>
          </b-form-group>
          <b-form-group label="Record Count">
            <b-form-input
              type="number"
              placeholder="e.g. 100"
              v-model="file.record_count"
              trim
            />
          </b-form-group>
        </b-form>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import FormWizard from "@/components/CustomFormWizard";
import TabContent from "@/components/CustomTabContent";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  name: "FileEditor",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    FormWizard,
    TabContent,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bind: [],
      schemas: {},
    };
  },
  computed: {
    schemaList() {
      return [
        {
          value: null,
          text: "Please select a schema",
          disabled: true,
        },
        ...Object.keys(this.schemas).map((schema) => {
          return {
            value: schema,
            text: this.schemas[schema].name,
          };
        }),
      ];
    },
    isNew() {
      return !("path" in this.file);
    },
  },
  watch: {
    projectId: {
      immediate: true,
      handler(id) {
        if (id) {
          this.bindObject("schemas", id, "schemas");
        }
      },
    },
  },
  methods: {
    save(exit = false) {
      if (exit) {
        this.$emit("save-and-exit", this.file);
      } else {
        this.$emit("save", this.file);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.card-wrapper {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.field-card {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 10%) !important;
  overflow-y: auto;
  height: 100%;
  min-height: 400px;
  max-height: 592px;
}

.no-body-margin {
  margin-left: -20px;
  margin-right: -20px;
  width: 107.5%;
}

.card-title-button {
  margin-top: -5px;
}

.list-header-button {
  width: 107%;
  margin-left: -16px !important;
  margin-right: -16px !important;
  text-align: left;
}

.list-method {
  padding: 0;
}

.prism-editor {
  height: 500px;
  border: 1px solid #545968;
  border-radius: 0.25rem;
  background: #363d4f;
  font-family: "Source Code Pro", serif;
}

.step-list {
  border: 1px solid #545968;
  border-radius: 0.25rem;
}

.list-handle {
  width: 30px;
  height: 28px;
  float: left;
  cursor: pointer;
}
</style>
