<template>
  <hierarchical-index
    :dbref="dbref"
    :title="title"
    :new-button-label="newButtonLabel"
    :fields="fields"
    :search-fields="searchFields"
  >
    <template #cell(naming)="data">
      <span v-if="!data.item.isFolder">
        {{ data.item.naming }}
      </span>
    </template>
    <template v-slot:index_actions="data">
      <b-button
        variant="primary"
        size="sm"
        class="ml-1"
        v-if="!data.item.isFolder"
        v-b-modal="'downloadFile' + data.item.key"
        :disabled="downloading.includes(data.item.key)"
      >
        <span v-if="downloading.includes(data.item.key)">
          <b-spinner small></b-spinner>
          Downloading
        </span>
        <span v-if="!downloading.includes(data.item.key)">
          <i class="fas fa-download"></i> Download
        </span>
      </b-button>

      <b-modal
        :id="'downloadFile' + data.item.key"
        title="Download File"
        ok-title="Download"
        ok-variant="primary"
        @ok="download(data.item)"
        @show="downloadCount = data.item.record_count"
      >
        <b-form-group label="Record Count">
          <b-form-input
            type="number"
            v-model="downloadCount"
            placeholder="e.g. 50"
          />
        </b-form-group>
      </b-modal>
    </template>
  </hierarchical-index>
</template>

<script>
import { mapState } from "vuex";
import HierarchicalIndex from "@/components/HierarchicalIndex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Files from "@/firebase/files";

export default {
  name: "Files",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    HierarchicalIndex,
  },
  data() {
    return {
      dbref: "files",
      title: "Files",
      newButtonLabel: "New Flat File",
      fields: [
        {
          key: "select",
          label: "",
          sortable: false,
        },
        {
          key: "icon",
          label: "",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
        },
        {
          key: "naming",
          label: "Naming",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      searchFields: ["name", "description", "naming"],
      downloadCount: 50,
      downloading: [],
    };
  },
  computed: {
    ...mapState(["userProfile"]),
  },
  methods: {
    async download(item) {
      const fileKey = item.key;
      this.downloading.push(fileKey);
      console.log(item);
      try {
        // Get selected project path
        const project = await this.getObjectSnapshot(
          "selected",
          this.userProfile.uid
        );
        // Get file contents
        const f = new Files();
        const resp = await f.downloadFile(
          project.path,
          fileKey,
          this.downloadCount
        );
        const content = resp.data;
        // Save file
        this.saveFile(content.name, content.content);
      } catch (e) {
        console.error(e);
        this.notyf.error(
          "An error occurred downloading the file. Please try again later."
        );
      }
      this.downloading.splice(this.downloading.indexOf(fileKey), 1);
    },
  },
};
</script>
