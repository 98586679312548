<template>
  <file-editor
    :file="file"
    :projectId="project.objectID"
    @save="update(false)"
    v-on:save-and-exit="update(true)"
    v-if="project.objectID"
  ></file-editor>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import FileEditor from "@/components/FileEditor";

export default {
  name: "FileEdit",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    FileEditor,
  },
  data() {
    return {
      project: {},
      refkey: null,
      file: {
        name: "",
        description: "",
        delimiter: ",",
        qualifier: '"',
        use_qualifier: "always",
        eol_format: "linux",
        schema: null,
        record_count: 50,
      },
      sources: [],
      root: ["folders", "files", ""],
      path: "",
      endpoints: {},
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    userUid() {
      return this.userProfile.uid;
    },
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        this.bindObject("selected", uid, "project");
      },
    },
    project: {
      immediate: true,
      handler(project) {
        if (project.objectID) {
          this.root[2] = project.objectID;
          console.log("Project", project.objectID, this.$route.query.path);
          if (this.$route.query.path !== undefined) {
            this.setRootFromPath(this.$route.query.path);
            this.path = this.$route.query.path
              .split("/")
              .slice(2, this.$route.query.path.split("/").length)
              .join("/");
          }
          if (this.refkey) {
            this.bindObject("files/" + project.objectID, this.refkey, "files");
          }
        }
      },
    },
    refkey: {
      immediate: true,
      handler(key) {
        if (key && this.project.objectID) {
          this.bindObject("files/" + this.project.objectID, key, "file");
        }
      },
    },
  },
  methods: {
    async update(exit = false) {
      const file = { ...this.file };
      // Update file
      let promises = [
        this.updateObject("files/" + this.project.objectID, this.refkey, file),
        this.updateObject(this.root.join("/"), this.refkey, file),
      ];
      // Resolve promises and send notifications
      Promise.all(promises)
        .then(() => {
          this.notyf.success("File saved successfully");
          if (exit) {
            this.navigate("files", { path: this.path });
          }
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error("An error occurred while saving the file");
        });
    },
    async setRootFromPath(routePath) {
      console.log("Route Path: " + routePath);
      const segments = routePath.split("/");
      let root = ["folders", "files", this.project.objectID];
      for (const segment of segments) {
        const snapshot = await this.getObjectSnapshot(
          root.join("/"),
          null,
          true
        );
        const folder = snapshot.val();
        console.log("Folder", folder);
        if (folder && "folders" in folder) {
          Object.keys(folder.folders).forEach((subkey) => {
            const subfolder = folder.folders[subkey];
            if (subfolder.slug === segment.toLowerCase()) {
              root.push("folders", subkey);
            }
          });
        }
      }
      this.root = [...root, "items"];
    },
  },
  created() {
    this.refkey = this.$route.params.id;
  },
};
</script>

<style lang="css" scoped>
.field-card {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 10%) !important;
}

.no-body-margin {
  margin-left: -20px;
  margin-right: -20px;
  width: 107.5%;
}

.card-title-button {
  margin-top: -5px;
}

.list-header-button {
  width: 107%;
  margin-left: -16px !important;
  margin-right: -16px !important;
  text-align: left;
}

.list-method {
  padding-top: 0;
  padding-bottom: 0;
}

.prism-editor {
  height: 500px;
  border: 1px solid #545968;
  border-radius: 0.25rem;
  background: #363d4f;
}
</style>
